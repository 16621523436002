.table-header {
    border: 0px;
    background-color: #F5F5F5;
    padding-bottom: 0px;
}

.table-header-item {
    border: 0px;
    padding-bottom: 0px;
}

.custom-badge {
    background-color: var(--status-color) !important; /* Important to override Bootstrap */
}
  
.custom-badge {
    color: #ffffff; /* Set text color to white */
}