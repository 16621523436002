.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.img-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; 
    margin-top: 10px; 
  }
  
  .img-logo a {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
  }
  
  .img-logo img {
    max-width: 80%;
    display: block;
  }

.form-group {
    width: 100%;
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    text-align: center;
    font-weight: bold;
}

.form-control {
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ced4da;
    width: 100%;
}

.btn-primary {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: none;
    background-color: #122C6B;
    color: white;
    margin-top: 1rem;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #1A3B85;
}

.errorModalTitle {
    text-align: center !important;
}

@media (max-width: 768px) {
    .login-container {
        padding: 10px;
    }

    form {
        padding: 15px;
    }
}