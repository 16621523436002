/* Layout.css */
.navbar {
  background-color: #122C6B;
  color: white;
}

.navbar a,
.navbar a:hover,
.navbar a:active {
  color: white;
}

.sidebar {
  background-color: #122C6B;
  color: white;
  height: 100vh; /* Full height */
  position: fixed; /* Fix the sidebar to the side */
  overflow: hidden; /* Prevent scrolling */
  width: 16.6667%; /* Width equivalent to 2 columns */
}

.custom-card {
  background-color: transparent; /* Set background color of the card to transparent */
  border: none; /* Remove border */
}

.custom-link {
  color: white;
  transition: color 0.2s, background-color 0.2s;
}

.custom-link:hover {
  color: lightblue;
}

.custom-link-active {
  color: #122C6B;
}

#page-content-wrapper {
  margin-left: 16.6667%; /* Offset the width of the sidebar (2/12 columns) */
  overflow-y: auto; /* Allow vertical scrolling */
  height: 100vh; /* Ensure it takes up the full height of the viewport */
  padding: 20px; /* Add some padding */
}

.mt-auto {
  margin-top: auto; /* Ensure the logout button is at the bottom */
}

.pb-4 {
  padding-bottom: 1.5rem; /* Add padding bottom */
}


